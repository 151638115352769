import React from "react"

import DrawSpanHtml from "./draw-span-html";
import UnderlinedHtml from "./underlined-html"
import HighlightedHtml from "./highlighted-html";

const StyledBlock = ({html, gotoFunc}) => {
	if(!Array.isArray(html) && typeof html === 'object' && html !== null){
		return (
			<DrawSpanHtml html={html} gotoFunc={gotoFunc} />
		)
	}
	
	return (
	<>
		{!Array.isArray(html) && !html.startsWith('_') && !html.startsWith('|') && <DrawSpanHtml html={html} gotoFunc={gotoFunc}/>}
		{!Array.isArray(html) && html.startsWith('_') && <UnderlinedHtml html={html} gotoFunc={gotoFunc} /> }
		{!Array.isArray(html) && html.startsWith('|') && <HighlightedHtml html={html} gotoFunc={gotoFunc} />}
		{Array.isArray(html) && html.map((t,idx)=>t.startsWith('_') || t.startsWith('|') ? t.startsWith('_') ? <UnderlinedHtml key={`und${idx}`} html={t} gotoFunc={gotoFunc} /> : <HighlightedHtml key={`hi${idx}`} html={t} gotoFunc={gotoFunc} /> : (<DrawSpanHtml key={`xyz${idx}`} html={t} gotoFunc={gotoFunc} />)
	)}
	</>
)}

const StyledHtml = ({html, classes='', gotoFunc}) => {
	const styledClasses=`antialiased self-center justify-self-star ${classes}`
	
	return (
		<p className={styledClasses}>
			{!Array.isArray(html) && (
				<StyledBlock html={html} gotoFunc={gotoFunc} />	
			)}
			{Array.isArray(html) && (
				<>
				{html.map((html, bidx) => <StyledBlock  key={`bul${bidx}`} html={html} gotoFunc={gotoFunc} />)}
				</>
			)}
		</p>
)};

export default StyledHtml
