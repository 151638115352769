import React from "react"
import DrawSpanHtml from "./draw-span-html";

const HighlightedHtml = ({html,gotoFunc}) => {
	let colorClass =html.substr(html.indexOf('|', 1)+1) ||  'bg-blue-100';
	
	let text = html;
	if(html.startsWith('|')){
		text=html.substr(1,html.indexOf('|',1)-1)
	}
	const classes = `pb-1 rounded-sm ${colorClass}`
	return (

	// <span className="relative">
	// 	<span style={{height:'6px'}} className={classes}></span>
	// 	<DrawSpanHtml html={text} />
	// </span> 

	<span className="leading-8">
	<span className={`px-2 pb-1 rounded-sm ${classes}`}><DrawSpanHtml html={text} gotoFunc={gotoFunc}/></span> 
	</span>
)
};

export default HighlightedHtml

