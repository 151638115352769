import React from "react"

import Layout from "../layouts"
import SEO from "../components/seo"
import DetailsSlides from "../components/details-slides"

const basicTemplate = props => {
  const { pageContext } = props
  const pageData  = pageContext.pageData
    console.log('props',props)
    console.log('context', pageContext)
    console.log('data', pageData)
  return (
    // <Layout location={props.location}>
    <>
    <Layout location={props.location}>
    <SEO title={pageData.seo_title} />  
    <div >
        <DetailsSlides key={pageData.seo_title} location={props.location} data={pageData}/>
    </div>
    {/* <Footer  /> */}
    </Layout>
    </>
    
  )
}
export default basicTemplate