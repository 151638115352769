import React from "react"

const DrawSpanHtml = ({html, gotoFunc}) => {
	let el=null;
	const linkRe = /(~~~[^~]+~~~)/gm;
	var linkText
	if(html.hasOwnProperty("bullet") || html.hasOwnProperty("list")) {
		el =  
			html.list.map((t,idx)=><li key={`sp${idx}`}  className="ml-6" dangerouslySetInnerHTML={{__html:  t}} />)
		html = html.bullet || ""
	}

	const m = linkRe.exec(html)

	if(m!==null){
		console.log(m)
		html = html.replace(m[1], '')
		const linkData = m[1].replaceAll('~','').split("#")
		linkText = linkData[0]
		var linkParam = linkData[1]
		var textBefore = html.substring(0,m.index)
		var textAfter = html.substring(m.index)
	}
	return (
		<>
		{linkText && (
			<>
			{/* <span dangerouslySetInnerHTML={{__html:  textBefore}} /> */}
			{textBefore && (<span dangerouslySetInnerHTML={{__html:  textBefore}} />)}
			<a href="#" 
				className="font-bold text-blue-500 hover:underline"  
				onClick={()=>{gotoFunc(linkParam)}}>
				<span dangerouslySetInnerHTML={{__html:  linkText}}></span>
			</a>
			{textAfter && (<span dangerouslySetInnerHTML={{__html:  textAfter}} />)}
			</>
		)}
		{!Array.isArray(html) &&!linkText && <span dangerouslySetInnerHTML={{__html:  html}} />}
		{ el && <ul className="list-disc mt-2"> {el} </ul>}
		</>
	);
}


export default DrawSpanHtml
