import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GifVideo from './gifvideo';

const StoryVideo = props => (
    <StaticQuery
      query={graphql`
        query {
          content: allFile(filter: { extension: { eq: "mp4" } }) {
            edges {
              node {
                publicURL
                name
                }
              }
            }
        }
      `}
      render={data => {
        console.log(`Lookig for ${props.filename}`)
        const video = data.content.edges.find(n => {
          return n.node.publicURL.includes(props.filename);
        });
        if (!video) {
          return null;
        }
  
        //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
        return <GifVideo src={video.node.publicURL} {...props}/>;
      }}
    />
  );
  
  export default StoryVideo;