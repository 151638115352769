import React from "react"

import { getCurrentLangKey } from 'ptz-i18n';
import { langs, defaultLangKey } from '../data/languages'


import { withPrefix }  from 'gatsby-link';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import StoryImage from "./story-image"
import StoryVideo from "./story-video"
import StyledHtml from "./styled-html"

const DetailsSlideImage = ({ name, selected }) => (
  <div key={`si${selected.order}`}>

  { selected.image && ( 
        <div style={{ maxWidth: `1600px`, marginBottom: `0rem` }}>
          <StoryImage
            alt="{selected.image}" filename={`${name}/${selected.image}`} />
        </div>
  )}
  { selected.video && (
      <StoryVideo filename={`${selected.video}`} />
  )}   
  </div>
)

const DetailsNavButtonNext = ({ text, onClick }) => (
  <a role="button" onClick={onClick} className="mr-2 self-start focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
    <svg xmlns="http://www.w3.org/2000/svg" className="inline w-6 stroke-current stroke-2 transition duration-500 ease-in-out transform hover:-translate-x-1 hover:scale-110" viewBox="0 0 24 24" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <line className="st0" x1="19" y1="12" x2="5" y2="12" />
      <polyline className="st0" points="12,19 5,12 12,5 " />
    </svg>

  </a>
)

const DetailsNavButtonPrev = ({ text, onClick }) => (
  <a role="button" onClick={onClick} className="self-start focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
    <svg xmlns="http://www.w3.org/2000/svg" className="inline w-6 stroke-current stroke-2 transition duration-500 ease-in-out transform hover:-translate-x-1 hover:scale-110" viewBox="0 0 24 24" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <line className="st0" x1="5" y1="12" x2="19" y2="12" />
      <polyline className="st0" points="12,19 19,12 12,5 " />
    </svg>

  </a>
)

class DetailsSlides extends React.Component {
  render() {
    const url = this.props.location.pathname;
    let noPrefixUrl = url.replace(withPrefix('/'),'/') 
  
    const langKey = getCurrentLangKey(langs, defaultLangKey, noPrefixUrl);
    const backUrl = langKey===defaultLangKey ? `/#${this.props.data.name}`: `..#${this.props.data.name}`;
    const gotoFuncRef = this.goto.bind(this)
    return (
      <div className="bg-gray-100 h-screen">
        {/* Start of main */}
        <div className="flex flex-wrap md:h-screen bg-gray-100">
          <div className="flex flex-col bg-white w-full md:w-8/12 pt-3 pb-0 pl-3 pr-3 sm:pl-6 sm:pr-6">
            {/* Start of Navigation */}
            <div className="flex-none sticky top-0 z-50 bg-white border-b-2 border-gray-50">

              <div className="grid grid-flow-col grid-cols-3 grid-rows-1 gap-0 align-middle pb-1">
                <div className="flex-none flex align-middle self-center">
                  <div>
                    <AnchorLink to={backUrl}>
                      {/* <span className="hover:text-blue-150 hover:underline"><FormattedMessage id="LABEL_BACK" /></span>
                    <br/> */}
                      <svg xmlns="http://www.w3.org/2000/svg" className="opacity-70 pr-2 hover:text-blue-150 inline w-10 stroke-current stroke-0 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" viewBox="0 0 24 24" fill="currentColor" strokeLinecap="round" strokeLinejoin="round">

                        <path d="M15.6,1.7c1.5,0.2,2.3,1.7,2.3,3.4c-0.2,1.7-1.3,3.2-2.8,3c-1.5-0.2-2.3-1.7-2.3-3.4S14.1,1.5,15.6,1.7z M8.8,1.7
	c1.5,0,2.5,1.5,2.5,3.2S10.1,8,8.8,8S6.3,6.5,6.3,4.9S7.5,1.7,8.8,1.7z M1.6,9.7c-0.2-1.5,0.6-3,1.9-3.2S6,7.3,6.3,9
	c0.2,1.5-0.6,3-1.9,3.2C3.1,12.3,1.8,11.2,1.6,9.7z M16,20.3c-1.1,0-2.5-1.1-4.2-1.1s-2.5,1.1-4,1.1c-1.7,0-3.4-1.5-3.4-3.6
	c0-1.5,1.7-3,2.8-3.8c0.2-0.2,1.7-2.3,2.1-2.5c0.8-0.6,1.3-1.1,2.5-1.1c1.5,0,2.5,0.6,3.2,1.5c0.4,0.4,1.1,1.7,1.3,1.9
	c0.8,0.8,2.8,2.1,3,4C19.6,19.1,17.7,20.3,16,20.3z M22.4,10.2c-0.2,1.5-1.7,2.8-3,2.3c-1.3-0.2-2.1-1.7-1.9-3.4
	c0.2-1.5,1.7-2.8,3-2.3C21.8,7,22.6,8.5,22.4,10.2z"/>
                      </svg>

                    </AnchorLink>

                  </div>

                  <div className="hidden lg:inline">
                    <div className="text-left text-xs flex self-center" >
                      <div className="font-bold pr-6">
                        <AnchorLink to={backUrl}>
                          <span className="uppercase">
                            {this.props.data.title}</span>
                          <br />
                          <span className="font-normal antialiased text-xs">{this.props.data.subtitle}</span>

                        </AnchorLink>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="flex align-middle justify-center self-center">
                  <div className="self-center justify-center lg:text-xs text-xs mb-1 text-gray-900 font-light">
                    <span className="font-semibold">{this.state.selected.order + 1}</span>
                    &nbsp;/&nbsp;{this.props.data.slideshow.length}
                  </div>
                </div>
                <div className="justify-self-end self-center">
                  <div className="self-start focus:outline-none border-0 border-blue-50 rounded-full font-bold text-blue-150 px-0 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">

                    <div className="flex-none flex justify-between self-start">
                      <DetailsNavButtonNext text="&laquo;" onClick={this.prev} />
                      <DetailsNavButtonPrev text="&raquo;" onClick={this.next} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* `Start of Picture */}
            <div className="flex-initial my-auto">
              <DetailsSlideImage 
                name={this.props.data.name} selected={this.state.selected} />
            </div>

          </div>
          {/* End of Main */}
          {/* Start of Description */}
          <div className="bg-gray-100 w-full md:w-4/12 md:h-max p-3 sm:p-6 flex shadow-inner">


            <div className="flex flex-col my-auto">
              { this.state.selected.desc_image && (
                  <div className="flex-none ">
                    <>
                    {this.state.selected.desc_image_text && !Array.isArray(this.state.selected.desc_image_text) && (
                        <p className="antialiased pt-1 lg:text-base text-sm">
                          <StyledHtml html={this.state.selected.desc_image_text} gotoFunc={gotoFuncRef} />
                        </p>
                      )}

                      {this.state.selected.desc_image_text && Array.isArray(this.state.selected.desc_image_text) &&
                        this.state.selected.desc_image_text.map((text, idx) =>
                          (
                            <StyledHtml key={`desctxt${idx}`} classes="antialiased pt-1 lg:text-base text-sm pb-2" html={text} gotoFunc={gotoFuncRef}/>
                        ))
                      }
                    <div className="mx-auto" style={{ maxWidth: `300px`, maxHeight: `300px`, marginBottom: `1rem`, marginTop: `` }}>
                      <StoryImage
                          alt={`${this.props.data.name} description`} filename={`${this.props.data.name}/${this.state.selected.desc_image}`} />
                    </div>
                    </>
                </div>
                )}
                <div className={this.state.selected.desc_image?"flex-initial top-0":"flex-initial my-auto"}>
                  {!Array.isArray(this.state.selected.description) && (
                    <p className="antialiased pt-1 lg:text-base text-sm">
                      <StyledHtml html={this.state.selected.description} gotoFunc={gotoFuncRef} />
                    </p>
                  )}

                  {Array.isArray(this.state.selected.description) &&
                    this.state.selected.description.map((text, idx) =>
                      (
                        <StyledHtml key={`desc${idx}`} classes="antialiased pt-1 lg:text-base text-sm pb-2" html={text} gotoFunc={gotoFuncRef}/>
                    ))
                  }

                  { this.state.selected.bottom_desc_image && (
                      <div className="flex-none ">
                      <div className="mx-auto" style={{ maxWidth: `300px`, maxHeight: `300px`, marginBottom: `1rem`, marginTop: `` }}>
                      <StoryImage
                        alt={`${this.props.data.name} description`} filename={`${this.props.data.name}/${this.state.selected.bottom_desc_image}`} />
                    </div>
                    </div>
                    )}
               </div>  
              <div className="text-2xl flex-grow">

              </div>

            </div>
            <div >

            </div>

          </div>
          {/* End of Description */}
        </div>

      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = { selected: props.data.slideshow[0], hovered: props.data.slideshow[0] }
    this.story = props.data
    this.hoverSlide = this.hoverSlide.bind(this)
    this.unhoverSlide = this.unhoverSlide.bind(this)
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.props.data.slideshow.map((slide, index) => { slide.order = index })
  }


  hoverSlide(e) {
    if (this.state.selected.image === e.image) return;

    this.setState({ hovered: this.state.selected, selected: e })
    // console.log(`show ${e}`, e);
  }

  unhoverSlide(e) {
    // console.log(`hide ${e}`, e);
    if (this.state.selected.image === e.image) return;
    this.setState({ selected: this.state.hovered })
  }

  next() {
    let sel = this.state.selected;
    if (sel.order + 1 >= this.props.data.slideshow.length) {
      sel = this.props.data.slideshow[0];
    } else {
      sel = this.props.data.slideshow[sel.order + 1]
    }
    console.log(`${sel.image}`)
    this.setState({ selected: sel })
  }

  prev() {
    let sel = this.state.selected;
    if (sel.order - 1 < 0) {
      sel = this.props.data.slideshow[this.props.data.slideshow.length - 1];
    } else {
      sel = this.props.data.slideshow[sel.order - 1]
    }
    this.setState({ selected: sel })
  }

  goto(num) {
    console.log(`Go to ${num}`)
    let sel = this.state.selected;
    if (num>= this.props.data.slideshow.length) {
      sel = this.props.data.slideshow[0];
    } else {
      sel = this.props.data.slideshow[num]
    }
    this.setState({ selected: sel })
  }

}

export default DetailsSlides