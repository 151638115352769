import React from "react"
import DrawSpanHtml from "./draw-span-html";

const UnderlinedHtml = ({html, gotoFunc}) => {
	let colorClass =html.substr(html.indexOf('_', 1)+1) ||  'bg-blue-100';
	
	let text = html;
	if(html.startsWith('_')){
		text=html.substr(1,html.indexOf('_',1)-1)
	}
	const classes = `absolute top-5  w-full bg-opacity-60 ${colorClass}`
	return (

	<span className="relative">
		<span style={{height:'6px'}} className={classes}></span>
		<DrawSpanHtml html={text} gotoFunc={gotoFunc} />
	</span> 
)
};

export default UnderlinedHtml

